'use strict';

/* SLIGHT MODIFICATION FROM BASE POWEREREVIEWSRENDERING.JS */

if (window && !window.$) { // this check is for SG, in SG window.$ is already exist and throw error on reinitialize
    window.jQuery = window.$ = require('jquery');
}

var $prCategorySnippets = $('.pr-category-snippet');
var $prWarForm = $('.pr-war-form');
var $prPdpComponents = $('.pr-pdp-components');

/* eslint-disable no-undef */

/**
 * @function prLoadCategorySnippets
 */
function prLoadCategorySnippets() {
    if (window.POWERREVIEW) {
        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments);
        };
        var prCategorySnippets = [];
        if ($prCategorySnippets.length > 0) {
            $($prCategorySnippets).each(function (index) {
                if ($(this).data('pr-plp-component')) {
                    prCategorySnippets.push(Object.assign({}, $(this).data('pr-plp-component')));
                }
            });
        }
        if (prCategorySnippets.length > 0) {
            pwr('render', prCategorySnippets);
        }
    }
}

/**
 * @function prLoadWarForm
 */
function prLoadWarForm() {
    if (window.POWERREVIEW) {
        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments);
        };
        if ($prWarForm.length > 0 && $prWarForm.data('pr-war-form')) {
            var prWarFormData = $prWarForm.data('pr-war-form');
            // add on_submit callback method
            prWarFormData.on_submit = function () { // eslint-disable-line
                window.scrollTo(0, 0);
            };
            pwr('render',  Object.assign({}, prWarFormData));
        }
    }
}

function openCollapsedReviews () {
    var $collapsableContent = $('.product-reviews .collapsible-content-group');
    var $button = $collapsableContent.find('.title').eq(0);

    window.scrollTo({
        top: $button.offset().top,
        left: 0,
        behavior: 'smooth'
    });

    // Backup method for a collapsed Reviews section
    if (!$collapsableContent.hasClass('active')) {
        // scroll at the end of execution thread
        $button.trigger('click');
    }
}

/**
 * @function prLoadPdpComponents
 * @param {Object} powerReviewsData --powerReviewsData from product variant
 */
function prLoadPdpComponents(powerReviewsData) {
    if (window.POWERREVIEW) {
        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments);
        };
        if ($prPdpComponents.length > 0) {
            var prPdpData = powerReviewsData || $prPdpComponents.data('pr-pdp-components');
            pwr('render',  Object.assign({}, {on_read_reviews_click: openCollapsedReviews}, prPdpData));
        }
    }
}

// DOM Ready
$(function () {
    if ($prCategorySnippets.length > 0) { // only fire if user is on PLP page
        prLoadCategorySnippets();
    }
    if ($prWarForm.length > 0) { // only fire if user is on write review page
        prLoadWarForm();
    }
    if ($prPdpComponents.length > 0) { // only fires if user is on PDP page
        prLoadPdpComponents();
    }
    $('body').on('product:updateAvailability', function (e, response) {
        prLoadPdpComponents(response.product.powerReviewsComponents);
    });
});

$(document).ajaxComplete(function (event, xhr, settings) {
    if (settings.url.includes('Search-UpdateGrid') || settings.url.includes('Tile-Show') || $('#search-result-items').length > 0 || $('#product-search-results').length > 0 || $('#content-search-results-pane').length > 0) {
        /* need to get new number of product
        divs if added new (#search-result-items is for SG) */
        $prCategorySnippets = $('.pr-category-snippet');
        prLoadCategorySnippets();
    }
    if ($('#product-content').length > 0) { // this is for SG PDP variant change
        $prPdpComponents = $('.pr-pdp-components');
        prLoadPdpComponents();
    }
});

$(document).on('producttile:loaded', function (e) {
    $prCategorySnippets = $(e.target || e.currentTarget).find('.pr-category-snippet');
    prLoadCategorySnippets();
});